/* You can add global styles to this file, and also import other style files */
body{margin:0}
p,a,h1,h2,h6,input,select{font-family: "Montserrat", Sans-serif;font-weight: 500}
input,select{font-size: 18px}
h1{color:white;font-size: 22px;font-weight: 600}
right li.con:hover{background: #BD7C6A}
/*menu*/
.menu{ background-color:#b4b0ae;display:flex;padding: 0 60px;min-height:85px}
.left{width:30%}
.left img{height:48px;margin-top: 14px}
.right{width: 70%}

.right li{list-style: none;padding:30px;}
.right a{color:white;text-decoration:none;font-size: 20px;font-weight: 500}
.right li.des:hover{background: #233264}
.right li.apa:hover{background: #D2B9AA}
.right li.nei:hover{background: #2D4605}
.right li.ben:hover{background: #C4A299}
.right li.con:hover{background: #BD7C6A}
/*content*/
.content{background-image:url("assets/image/plant.jpg");padding: 80px 0}
.process{background-image:url("assets/image/contact-backg.svg");width:80%;margin:auto;;max-width: 1440px;padding: 60px;background-size: cover;}
.bannerone{background-color: #d4ab9f;padding: 40px; }
.bannerone div {display: table-cell;}
h1.circle {background-color:#bd7c6a;padding:4px 10px;border-radius: 52px;font-size: 18px;width: 10px;margin-right: 10px}
.bannerone p{color:white;font-size:20px}
.bannertwo{background-color: #d4ab9f;padding: 40px;margin-top: 15px}
.processtwo {display: flex;align-content: center;align-items: center;}
.processtwo hr {border: solid white 1px;width: 45%;}

/*form*/
.rendered-form {
    overflow: hidden;
		margin: 40px 0;
}
.two-column-form {
    display: flex;
    width: 100%;
    justify-content: center;
}
.form-group {
    width: 90%;
    margin: 20px;
}
.form-group-solo {
    width: 100%;
		margin: 0 20px;
}

.form-group input, .form-group select,.form-group-solo input {
    background: #FDFBF5;
    border: none;
    box-shadow: 0px 0px 3px 0px #05050552;
    padding: 9px;
}
.two-column-form {
    display: flex;
    width: 100%;
    justify-content: center;
}
.form-group input, .form-group select,.form-group-solo input {
    background: #FDFBF5;
    border: none;
    box-shadow: 0px 0px 3px 0px #05050552;
    padding: 9px;
}
select#mes-exp,select#ano-exp,input#CCV {
    width: 100%;
}
.one-c{width:100%;}
.tr-c{width:33%}
.bot {
    text-align: center;}
    button#suscribirte {
    background: none;
    padding: 20px;
    border-radius: 60px;
    width: 176px;
    border: solid 2px white;
    color: white;
    font-size: 20px;
    font-style: oblique;
}
.form-group input, .form-group-solo input, .form-group select{
    background: #e9d4cb;
    padding: 15px 10px;
    border-radius: 10px;
}
.card {
    margin-left: 22px;
}
.card h6 {
    color: white;
    font-size: 14px;
    margin: 22px 0;
}
@media(max-width:750px){
  .bannertwo{padding: 20px;}
  h1{font-size:18px!important;}
  .process{padding: 30px!important}
}





.mobile-menu {
  display:block;
  width:100%;
line-height: 1.6em;
font-weight: 400;
width:100%;
text-align:center;
	position: relative;
  margin:0 auto;
}
/*Strip the ul of padding and list styling*/
.mobile-menu ul {
	list-style-type:none;
  margin: 0 auto;
  padding-left:0;
  text-align:center;
  width:100%;
  position: absolute;
}

/*Create a horizontal list with spacing*/
.mobile-menu li {
	display:inline-block;
	/*float: left;
	margin-right: 1px;*/
}

/*Style for menu links*/
.mobile-menu li a {
	display:block;
	min-width:140px;
	text-align: center;
	text-decoration: none;
-webkit-transition: all 0.4s ease 0s;
-moz-transition: all 0.4s ease 0s;
-ms-transition: all 0.4s ease 0s;
-o-transition: all 0.4s ease 0s;
transition: all 0.4s ease 0s;
}

/*Hover state for top level links*/


/*Style for dropdown links*/
.mobile-menu li:hover ul a {
	background: #f3f3f3;
	color: #2f3036;
	height: 40px;
	line-height: 40px;
}

/*Hover state for dropdown links*/
.mobile-menu li:hover .mobile-menu ul a:hover {
	color: #4db3ff;
}

/*Hide dropdown links until they are needed*/
.mobile-menu li ul {
	display: none;
}

/*Make dropdown links vertical*/
.mobile-menu li ul li {
	display: block;
	float: none;
}

/*Prevent text wrapping*/
.mobile-menu li ul li a {
	width: auto;
	min-width: 100px;
	padding: 0 20px;
}


/*Style 'show menu' label button and hide it by default*/
.mobile-menu .show-menu {
	text-decoration: none;
	color: #fff;
	text-align: center;
	padding: 10px 15px;
	display: none;
  cursor: pointer;
  text-transform: uppercase;
}

.mobile-menu .show-menu span{
   padding-left: 25px;
}

/*Hide checkbox*/
.mobile-menu input[type=checkbox]{
    display: none;
}

/*Show menu when invisible checkbox is checked*/
.mobile-menu input[type=checkbox]:checked ~ #menu{
    display: block;
}

/*Responsive Styles*/

@media screen and (max-width : 800px){
  /*Show menu when invisible checkbox is checked*/
  .mobile-menu input[type=checkbox]:checked ~ #menu{
      display: block;
      margin-left: -80px;
      margin-top: 20px;
  }
  .mobile-menu .lines {
    border-bottom: 15px double #f8f8f8;
    border-top: 5px solid #f8f8f8;
    content:"";
    height: 5px;
    width:20px;
    padding-right:15px;
  float: right;
}
	/*Make dropdown links appear inline*/
	.mobile-menu ul {
		position: static;
		display: none;
	}
	/*Create vertical spacing*/
	.mobile-menu li {
		margin-bottom: 1px;
	}
	/*Make all menu links full width*/
	.mobile-menu ul li, .mobile-menu li a {
		width: 100%;
	}
	/*Display 'show menu' link*/
	.mobile-menu .show-menu {
		display:block;
    margin-top: 20px
	}
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
